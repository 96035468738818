import React from 'react'

import { graphql } from 'gatsby'

import SEO from '../components/seo'

import Layout from '../components/layout'

import SignUpForm from '../components/sign-up-form'

export default ({ data }) => {
    let page = data.allWordpressPage.nodes[0]

    return (
        <Layout noFooter noSocialBar noScrollTop noHeader>
            <SEO
                title={page.yoast_meta.yoast_wpseo_title}
                description={page.yoast_meta.yoast_wpseo_metadesc}
            />
            <SignUpForm />
        </Layout>
    )
}

export const query = graphql`
    {
        allWordpressPage(filter: { slug: { eq: "sign-up" } }) {
            nodes {
                yoast_meta {
                    yoast_wpseo_title
                    yoast_wpseo_metadesc
                }
            }
        }
    }
`
