import React, { useRef, useEffect, useState } from 'react'
import Logo from '../logo'
import styles from './style.module.scss'
import cn from 'classnames'
import { Link, useStaticQuery, graphql, navigate } from 'gatsby'
import { AiFillCheckSquare } from 'react-icons/ai'
import Input from '../input'
import Button from '../button'
import { useRegisterAccount, useSignIn, useMyself } from '../hooks'
import Image from 'gatsby-image'
import { get } from 'lodash-es'
import Form from '../form/api-form'
import { ACCOUNT_TYPE_OWNER, ACCOUNT_TYPE_TENANT_BUYER } from '../../constants'

const FEATURES = [
    'Easy sign up.',
    'No credit card is required.',
    'Free for ever.',
    'List your property in 2 easy steps.',
    '100% online rental portal.',
]

export default () => {
    const formRef = useRef({})
    const { statusCode, submit, loading, response } = useRegisterAccount({
        formRef,
    })
    const {
        statusCode: signInStatusCode,
        submit: submitSignIn,
        loading: loadingSignIn,
    } = useSignIn({ formRef })

    const [accountType, setAccountType] = useState(null)

    const [success, setSuccess] = useState(false)

    const data = useStaticQuery(graphql`
        query {
            logoIcon: file(relativePath: { eq: "icon.png" }) {
                childImageSharp {
                    fluid(maxHeight: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    useEffect(() => {
        if (statusCode === 200 && !loadingSignIn) {
            submitSignIn()
        }

        if (signInStatusCode === 200) {
            setSuccess(true)

            if (accountType === ACCOUNT_TYPE_OWNER)
                setTimeout(() => {
                    navigate('/owner-dashboard')
                }, 500)
            else {
                setTimeout(() => {
                    navigate('/')
                }, 500)
            }
        }
    }, [statusCode, loadingSignIn, signInStatusCode])

    const logoIcon = get(data, 'logoIcon.childImageSharp.fluid')

    return (
        <div className={styles.full}>
            <div className={cn('row', styles.row)}>
                <div
                    className={cn(
                        'col-xs-12 col-md-6',
                        styles.leftCol,
                        styles.col
                    )}
                >
                    <div className={styles.features}>
                        <Logo className={styles.logo} />
                        <ul className={styles.featuresList}>
                            {FEATURES.map((f) => {
                                return (
                                    <li key={f}>
                                        <AiFillCheckSquare
                                            className={styles.icon}
                                        />
                                        {f}
                                    </li>
                                )
                            })}
                        </ul>
                        {logoIcon && (
                            <div className={styles.logoIconWrapper}>
                                <Image
                                    className={styles.logoIcon}
                                    fluid={logoIcon}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={cn(
                        'col-xs-12 col-md-6',
                        styles.rightCol,
                        styles.col
                    )}
                >
                    <div className={styles.formWrapper}>
                        <Form
                            statusCode={statusCode}
                            ref={formRef}
                            loading={loading || loadingSignIn}
                            response={response}
                            onSubmit={() => submit()}
                        >
                            <h1 className={styles.title}>
                                Create your Property ePortal account
                            </h1>

                            <h2 className={styles.subTitle}>
                                Already have an account?{' '}
                                <Link to="/sign-in" className={styles.signIn}>
                                    Sign in
                                </Link>
                            </h2>

                            <Input label={'Name'} name="name" required />

                            <Input
                                label={'Email'}
                                name="email"
                                type="email"
                                required
                            />

                            <Input
                                label="Password"
                                type="password"
                                name="password"
                                required
                                comment="Password must be at least 4 characters, and must include at least one upper case letter, one lower case letter, and one numeric digit."
                            />

                            <Input
                                label="Password confirmation"
                                type="password"
                                name="passwordConfirmation"
                                required
                            />

                            <label className={styles.account_typeLabel}>
                                Account type
                            </label>

                            <Input
                                type="radio"
                                name="account_type"
                                labels={['Owner', 'Tenant or buyer']}
                                values={[
                                    ACCOUNT_TYPE_OWNER,
                                    ACCOUNT_TYPE_TENANT_BUYER,
                                ]}
                                onChange={(value) => setAccountType(value)}
                            />
                            <Button className={styles.button}>Sign up</Button>
                        </Form>
                        {success && (
                            <div className={styles.successMessage}>
                                Welcome to Property ePortal, your account has
                                been created successfully, please wait a moment
                                while redirecting you.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
